import { createContext } from 'react';
import socketio from 'socket.io-client';

export const socket = socketio.connect(process.env.BASE_API_URL);
// export const socket = socketio.connect('http://203.21.120.174:8001');

socket.on('connect', function () {
  console.log('Socket Connected');
});

socket.on('disconnect', function () {
  console.log('Socket Disconnected');
});

export const SocketContext = createContext();
